@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;





* {
  font-family: "Figtree", serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}


.kanit-thin {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.kanit-extralight {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.kanit-light {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.kanit-medium {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.kanit-semibold {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.kanit-bold {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.kanit-extrabold {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.kanit-black {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.kanit-thin-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.kanit-extralight-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.kanit-light-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.kanit-regular-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.kanit-medium-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.kanit-semibold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.kanit-bold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.kanit-extrabold-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.kanit-black-italic {
  font-family: "Kanit", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.loader {
  /* width: 48px;
  height: 48px; */
  border: 3px solid #FFF;
  border-bottom-color: #2563eb;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 


    
